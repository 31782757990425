import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import {
  mapLabels,
  mapLabelsToStringArray,
} from '@app/utils/inventory-items.helper';
import { InventoryItemsFilter } from '@modules/filter/models/inventory-items-filter';
import { FilterService } from '@modules/filter/services/filter.service';
import { Configuration } from '@shared/models/configuration';
import { InventoryItem } from '@shared/models/inventory-item';
import { Label } from '@shared/models/label';
import { PageQuery } from '@shared/models/page-meta';
import { PagedResult } from '@shared/models/paged-result';
import { ShippingMethod } from '@shared/models/shipping-method';
import { defaultInventoryItemSort } from '@shared/models/sort-option';
import {
  BehaviorSubject,
  combineLatestWith,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';

export interface InventoryItemPatch
  extends Pick<
      InventoryItem,
      | 'a'
      | 'b'
      | 'h'
      | 'closedA'
      | 'closedH'
      | 'amount'
      | 'name'
      | 'labels'
      | 'coating'
      | 'winding'
      | 'rollDiameter'
    >,
    ShippingMethod {
  configurationId?: string;
  material?: string;
  productOptionIds?: string[];
  configurationAttributeValueIds?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class InventoryItemsService {
  private dataUpdateEmitter = new Subject<InventoryItem | undefined>();

  labels$ = new BehaviorSubject<Label[]>([]);

  constructor(
    private readonly apiService: ApiService,
    private readonly filterService: FilterService,
  ) {}

  public getLabels(): Observable<Label[]> {
    return this.labels$.pipe(
      combineLatestWith(this.filterService.inventoryItemFilter),
      switchMap(([labels, inventoryItemsFilter]) => {
        const inventoryItemsFilterLabels = mapLabelsToStringArray(
          inventoryItemsFilter?.labels,
        );

        return of(
          labels.map((label: Label) => ({
            ...label,
            active: inventoryItemsFilterLabels.includes(label.title),
          })),
        );
      }),
    );
  }

  public updateLabels(
    id: any,
    labels: InventoryItemPatch['labels'],
  ): Observable<InventoryItem> {
    return this.apiService.patch(`inventory/${id}`, { labels });
  }

  public loadLabels(): void {
    this.apiService
      .get(`inventory/labels`)
      .subscribe((labels: InventoryItemPatch['labels']) => {
        this.labels$.next(mapLabels(labels));
      });
  }

  findOne(id: string): Observable<InventoryItem> {
    return this.apiService.get(`inventory/${id}`);
  }

  findAll(query: PageQuery): Observable<PagedResult<InventoryItem>> {
    const inventoryItemsFilter: InventoryItemsFilter =
      this.filterService.inventoryItemFilter.getValue();

    const params = new URLSearchParams({
      limit: String(query.limit),
      page: String(query.page),
      sort:
        inventoryItemsFilter.sortOption?.sort || defaultInventoryItemSort.sort,
      order:
        inventoryItemsFilter.sortOption?.order ||
        defaultInventoryItemSort.order,
      filter: inventoryItemsFilter.productFilter,
    });

    if (inventoryItemsFilter.searchTerm) {
      params.append('search', inventoryItemsFilter.searchTerm);
    }

    const labels = mapLabelsToStringArray(inventoryItemsFilter.labels);

    labels.forEach((label) => {
      params.append('labels[]', label);
    });

    return this.apiService.get(`inventory?${params.toString()}`);
  }

  public update(
    id: string,
    fieldsToBeUpdated: Partial<InventoryItemPatch>,
  ): Observable<InventoryItem> {
    return this.apiService.patch(`inventory/${id}`, fieldsToBeUpdated);
  }

  public updateByConfiguration(
    inventoryItemId: string,
    configuration: Configuration,
  ): Observable<InventoryItem> {
    const fieldsToBeUpdated: Partial<InventoryItemPatch> = {
      a: configuration.a,
      b: configuration.b,
      h: configuration.h,
      closedA: configuration.closedA,
      closedH: configuration.closedH,
      amount: configuration.amount,
      material: configuration.material.id,
      coating: configuration.coating,
      winding: configuration.winding,
      rollDiameter: configuration.rollDiameter,
      deliveryCountryId: configuration.deliveryCountry.id,
      deliveryMethodId: configuration.deliveryMethod.id,
      productOptionIds: configuration.productOptions?.map(
        (productOption) => productOption.id,
      ),
      configurationAttributeValueIds:
        configuration.configurationAttributeValues?.map(
          (attributeValue) => attributeValue.id,
        ),
    };
    return this.apiService.patch(
      `inventory/${inventoryItemId}`,
      fieldsToBeUpdated,
    );
  }

  public delete(id: string): Observable<InventoryItem> {
    return this.apiService.delete(`inventory/${id}`);
  }

  public getDataUpdatedEmitter(): Subject<InventoryItem | undefined> {
    return this.dataUpdateEmitter;
  }

  public forceUpdate(): void {
    if (this.filterService.isFilterSet) {
      this.filterService.resetInventoryItemFilter();
    } else {
      this.dataUpdateEmitter.next(undefined);
    }
  }

  public create(
    configurationId: string,
    name: string,
    labels: string[],
  ): Observable<InventoryItem> {
    const body = {
      configurationId,
      name,
      labels,
    };
    return this.apiService.post('inventory', body);
  }

  public getDielineSvg(inventoryItem: InventoryItem) {
    return this.apiService.getRaw(
      `${ApiService.apiUrl}/inventory/${inventoryItem.id}/dieline?type=svg`,
      this.apiService.buildHttpOptions({
        contentType: 'application/svg',
        responseType: 'text',
      }),
    );
  }

  public batchDelete(inventoryItems: InventoryItem[]) {
    return this.apiService.delete('inventory', {
      body: {
        inventoryItemIds: inventoryItems.map((item) => item.id),
      },
    });
  }

  public batchUpdateLabels(inventoryItems: InventoryItem[], labels: string[]) {
    return this.apiService.patch('inventory', {
      inventoryItemIds: inventoryItems.map((item) => item.id),
      labels,
    });
  }
}
